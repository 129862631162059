import * as THREE from "three";
import { vertexShader, fragmentShader } from "./_shaders";
import { Constants } from "./_constants";

export default class Plane {
  constructor(width, height, ratio, scene) {
    this._clock = new THREE.Clock();
    this._isClockActive = false;
    this._uniforms = {
      u_resolution: {
        value: {
          x: width * ratio,
          y: height * ratio
        }
      },
      u_time: { value: 0.0 },
      u_positionX: { value: 1.5 },
      u_sinLong: { value: 0.5 },
      u_sinSize: { value: 0.2 },
      u_smooth: { value: Constants.NORMAL_SMOOTH },
      u_color: { value: Constants.COLOR_YELLOW },
      u_bottom: { value: false }
    };
    this._animations = [];
    this._planeMaterial = new THREE.ShaderMaterial({
      uniforms: this._uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader
    });
    this._planeGeometry = new THREE.PlaneGeometry(1800 * 2, 1600, 1, 1);
    this._plane = new THREE.Mesh(this._planeGeometry, this._planeMaterial);
    this._plane.position.z = -500;

    scene.add(this._plane);
  }

  updateSize(width, height, ratio) {
    this._uniforms.u_resolution.value = {
      x: width * ratio,
      y: height * ratio
    };
  }

  changeDiraction(to) {
    this._uniforms.u_bottom.value = to === "bottom" ? true : false;
  }

  createAnimation({ type, timing, to, duration, delay = 0 }) {
    setTimeout(() => {
      this._isClockActive = true;
      this._animations.forEach((animation, index) => {
        if (animation.type === type) {
          this._animations.splice(index, 1);
        }
      });

      const uniform =
        type === "x"
          ? this._uniforms.u_positionX
          : type === "long"
          ? this._uniforms.u_sinLong
          : type === "size"
          ? this._uniforms.u_sinSize
          : type === "color"
          ? this._uniforms.u_color
          : null;

      const animation = {
        type: type,
        timing: timing,
        to: to,
        from: uniform.value,
        uniform: uniform,
        duration: duration,
        start: performance.now()
      };

      this._animations.push(animation);
    }, delay);
  }

  updateAnimation(time) {
    this._animations.forEach((animation, index) => {
      let timeFraction = (time - animation.start) / animation.duration;
      if (timeFraction > 1) timeFraction = 1;

      const progress = animation.timing(timeFraction);

      if (animation.type !== "color") {
        const diff = animation.to - animation.from,
          val = animation.from + diff * progress;

        animation.uniform.value = val;
      } else {
        const diffR = animation.to.r - animation.from.r,
          valR = animation.from.r + diffR * progress;
        const diffG = animation.to.g - animation.from.g,
          valG = animation.from.g + diffG * progress;
        const diffB = animation.to.b - animation.from.b,
          valB = animation.from.b + diffB * progress;

        animation.uniform.value = { r: valR, g: valG, b: valB };
      }

      if (timeFraction === 1) this._animations.splice(index, 1);
    });

    if (this._isClockActive)
      this._uniforms.u_time.value = this._clock.getElapsedTime();
  }
}
