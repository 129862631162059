import * as THREE from "three";
import { Constants } from "./_constants";
import { getRandomInteger } from "../../utils/_other";
import Impetus from "impetus";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

export default class Models {
  constructor(isDesktop, isMobile, centerX, centerY, moodsArea) {
    this._isDesktop = isDesktop;
    this._isMobile = isMobile;
    this._animations = [];
    this.pizza = null;
    this.pizzaWrapper = new THREE.Group();
    this._pizzaRotationIsEnable = false;
    this.moodsArr = [];
    this.moods = new THREE.Group();
    this._moodsSelectIsEnable = false;
    this._moodsMotionArea = moodsArea;
    this._mouse = {
      x: centerX,
      y: centerY
    };
    this._touch = {
      x: 0,
      diff: 0
    };
    this._impetus = null;
    this._loaderGLTF = new GLTFLoader();
    this._loaderDRACO = new DRACOLoader();
    this._loaderDRACO.setDecoderPath("/draco/");
    this._loaderGLTF.setDRACOLoader(this._loaderDRACO);
  }

  _mouseMoveHandler = event => {
    this._mouse.x = event.clientX;
    this._mouse.y = event.clientY;
  };

  reset() {
    this._animations = [];
    this._touch = {
      x: 0,
      diff: 0
    };
    this.moodsPositionsSet();
  }

  load({ quantity, beforeLoad, loadDone }) {
    const modelsToLoad = Constants.MODELS_MOODS;

    if (quantity === "all") modelsToLoad.push(Constants.MODEL_PIZZA);

    const envMap = new THREE.CubeTextureLoader()
      .setPath(Constants.MODELS_PATH)
      .load([
        "envMap.jpg",
        "envMap.jpg",
        "envMap.jpg",
        "envMap.jpg",
        "envMap.jpg",
        "envMap.jpg"
      ]);

    const envMap2 = new THREE.CubeTextureLoader()
      .setPath(Constants.MODELS_PATH)
      .load([
        "envMap2.jpg",
        "envMap2.jpg",
        "envMap2.jpg",
        "envMap2.jpg",
        "envMap2.jpg",
        "envMap2.jpg"
      ]);

    modelsToLoad.forEach((obj, index) => {
      beforeLoad();

      const afterLoad = model => {
        if (obj.name === "main") {
          this.pizza = model.scene;
          this.pizzaWrapper.add(this.pizza);
          this.pizzaWrapper.scale.set(5, 5, 5);
        } else {
          const group = new THREE.Group();
          group.add(model.scene);
          if (index === 2) {
            group.scale.set(1.6, 1.6, 1.6);
          } else group.scale.set(2, 2, 2);

          if (obj.name === "party") {
            group.traverse(mesh => {
              if (
                mesh.type === "Mesh" &&
                mesh.material.name === "Material.016"
              ) {
                mesh.material.roughness = 0;
                mesh.material.blending = THREE.AdditiveBlending;
                mesh.material.vertexColors = true;
                mesh.material.depthWrite = false;
                mesh.material.depthTest = false;

                mesh.material.envMap = envMap;
              } else if (
                mesh.type === "Mesh" &&
                mesh.material.name === "Material.017"
              ) {
                mesh.material.color.setHex(0x000000);
              }
            });
          } else if (obj.name === "shar") {
            group.traverse(mesh => {
              if (
                mesh.type === "Mesh" &&
                mesh.material.name === "Material.009"
              ) {
                mesh.material.roughness = 0.12;
                mesh.material.metalness = 1;
                mesh.material.emissive.setHex(0x1b0808);

                mesh.material.envMap = envMap2;
              } else if (
                mesh.type === "Mesh" &&
                mesh.material.name === "Material.010"
              ) {
                mesh.material.emissive.setHex(0xdb9600);
              }
            });
          } else if (obj.name === "romantic") {
            group.traverse(mesh => {
              if (
                mesh.type === "Mesh" &&
                mesh.material.name === "Material.021"
              ) {
                mesh.material.roughness = 0.05;
                mesh.material.metalness = 0.8;

                mesh.material.envMap = envMap2;
              } else if (
                mesh.type === "Mesh" &&
                mesh.material.name === "default"
              ) {
                mesh.material.vertexColors = true;
                mesh.material.emissive.setHex(0xffffff);
                mesh.material.metalness = 0;
              }
            });
          }
          this.moodsArr[index] = group;
        }

        loadDone(index);
      };

      this._loaderGLTF.load(Constants.MODELS_PATH + obj.name + obj.type, glb =>
        afterLoad(glb)
      );
    });
  }

  createAnimation({
    obj,
    useUid = false,
    type = "forward",
    until = null,
    timing,
    draw = null,
    x = null,
    y = null,
    z = null,
    rotX = null,
    rotY = null,
    rotZ = null,
    duration = 1000,
    then = null,
    delay = 0,
    randomDelay = null
  }) {
    setTimeout(() => {
      const uid = useUid ? obj.uuid : null;

      if (uid) {
        this._animations.forEach((animation, index) => {
          if (animation.useUid && animation.obj.uuid === uid) {
            this._animations.splice(index, 1);
          }
        });
      }

      const animation = {
        obj: obj,
        useUid: useUid,
        type: type,
        until: until,
        timing: timing,
        draw: draw,
        xFrom: obj.position.x,
        xTo: x,
        yFrom: obj.position.y,
        yTo: y,
        zFrom: obj.position.z,
        zTo: z,
        rotXFrom: obj.rotation.x,
        rotXTo: rotX,
        rotYFrom: obj.rotation.y,
        rotYTo: rotY,
        rotZFrom: obj.rotation.z,
        rotZTo: rotZ,
        duration: duration,
        then: then,
        randomDelay: randomDelay,
        delay: delay,
        start: performance.now()
      };

      this._animations.push(animation);
    }, delay);
  }

  updateAnimation(time) {
    if (this._animations.length == 0) return;

    this._animations.forEach((animation, index) => {
      if (animation.until && animation.until()) {
        this._animations.splice(index, 1);
        return;
      }

      let timeFraction = (time - animation.start) / animation.duration;
      if (timeFraction > 1) timeFraction = 1;

      const progress = animation.timing(timeFraction);

      if (animation.draw) animation.draw(progress, animation);
      else {
        if (animation.xTo !== null) {
          const diffX = animation.xTo - animation.xFrom,
            valX = animation.xFrom + diffX * progress;

          animation.obj.position.x = valX;
        }

        if (animation.yTo !== null) {
          const diffY = animation.yTo - animation.yFrom,
            valY = animation.yFrom + diffY * progress;

          animation.obj.position.y = valY;
        }

        if (animation.zTo !== null) {
          const diffZ = animation.zTo - animation.zFrom,
            valZ = animation.zFrom + diffZ * progress;

          animation.obj.position.z = valZ;
        }

        if (animation.rotXTo !== null) {
          const diffRotX = animation.rotXTo - animation.rotXFrom,
            valRotX = animation.rotXFrom + diffRotX * progress;

          animation.obj.rotation.x = valRotX;
        }

        if (animation.rotYTo !== null) {
          const diffRotY = animation.rotYTo - animation.rotYFrom,
            valRotY = animation.rotYFrom + diffRotY * progress;

          animation.obj.rotation.y = valRotY;
        }

        if (animation.rotZTo !== null) {
          const diffRotZ = animation.rotZTo - animation.rotZFrom,
            valRotZ = animation.rotZFrom + diffRotZ * progress;

          animation.obj.rotation.z = valRotZ;
        }
      }

      if (timeFraction !== 1) return;

      if (animation.then) animation.then();
      this._animations.splice(index, 1);

      if (animation.type !== "infinite") return;

      this.createAnimation({
        obj: animation.obj,
        useUid: animation.useUid,
        type: "infinite",
        until: animation.until,
        timing: animation.timing,
        draw: animation.draw,
        x: animation.xTo !== null ? animation.xFrom : null,
        y: animation.yTo !== null ? animation.yFrom : null,
        z: animation.zTo !== null ? animation.zFrom : null,
        rotX: animation.rotXTo !== null ? animation.rotXFrom : null,
        rotY: animation.rotYTo !== null ? animation.rotYFrom : null,
        rotZ: animation.rotZTo !== null ? animation.rotZFrom : null,
        duration: animation.duration,
        then: animation.then,
        delay: animation.randomDelay
          ? getRandomInteger(
              animation.randomDelay.min,
              animation.randomDelay.max
            ) * 1000
          : 0
      });
    });
  }

  enableMoodsSelect(centerX, onTouchMove) {
    const area = document.querySelector(this._moodsMotionArea);

    if (this._isDesktop) {
      this._mouse.x = centerX;
      area.addEventListener("mousemove", this._mouseMoveHandler, false);
    } else {
      const bound = 370;

      this._impetus = new Impetus({
        source: area,
        boundX: [-bound, bound],
        update: x => {
          if (Math.abs(this._touch.diff - x) > 1) {
            onTouchMove();
          }
          this._touch.diff = x;
        }
      });
    }
    this._moodsSelectIsEnable = true;
  }

  disableMoodsSelect() {
    const area = document.querySelector(this._moodsMotionArea);

    this._moodsSelectIsEnable = false;
    if (this._isDesktop) {
      area.removeEventListener("mousemove", this._mouseMoveHandler, false);
    } else {
      this._impetus.destroy();
    }
  }

  updateMoodsCameraMotion({ ratio, camera, width, height, onLabelsUpdate }) {
    if (!this._moodsSelectIsEnable) return;

    if (this._isDesktop) {
      const mouseX = this._mouse.x - width / 2;

      const targetX = -mouseX * 0.0003;
      const x = 0.03 * (-targetX * (55 / ratio) - camera.position.x);
      camera.position.x += x;
    } else {
      const targetX = -this._touch.diff * 0.04;
      const x = 0.2 * (targetX - camera.position.x);
      camera.position.x += x;
    }

    this.moods.children.forEach((mood, index) => {
      const model = mood.children[0],
        newVec = new THREE.Vector3();

      model.getWorldPosition(newVec);

      const project = newVec.project(camera);
      project.x = ((project.x + 1) * width) / 2;
      project.y = (-(project.y - 1) * height) / 2;
      project.z = 0;

      onLabelsUpdate(index, project);
    });
  }

  setImpetusValue(val) {
    if (this._impetus) {
      this._touch.diff = val;
      this._impetus.setValues(val, 0);
    }
  }

  moodsPositionsSet() {
    this.moods.children.forEach((wrapper, index) => {
      wrapper.position.set(0, -20, 1);
      wrapper.rotation.x = -1;

      const model = wrapper.children[0];

      const pos = Constants.MOODS_ORIGINALS[index].position,
        rot = Constants.MOODS_ORIGINALS[index].rotation;

      model.position.set(pos.x, pos.y, pos.z);
      model.rotation.set(rot.x, rot.y, rot.z);
    });
  }

  init({ width, isMobile, scene }) {
    if (this.pizza) {
      setTimeout(() => {
        this.pizza.position.set(1.5, width < 1024 ? -3 : -1.5, -1000);
      }, 1000);
      this.pizza.rotation.set(0, 5.2, 0);

      scene.add(this.pizzaWrapper);
    }

    this.moodsArr.forEach(model => {
      this.moods.add(model);
    });

    this.moods.position.y = isMobile ? -3.5 : -4;
    setTimeout(() => {
      this.moodsPositionsSet();
    }, 1000);

    scene.add(this.moods);
  }
}
