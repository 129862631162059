import { isMobile } from "mobile-device-detect";

const cond = isMobile && window.innerWidth < window.innerHeight;

export const vertexShader = `void main() {
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;

export const fragmentShader = `
#ifdef GL_ES
precision highp float;
#endif

uniform vec2 u_resolution;
uniform vec3 u_color;
uniform float u_smooth;
uniform float u_time;
uniform float u_positionX;
uniform float u_sinLong;
uniform float u_sinSize;
uniform bool u_bottom;

float plot(vec2 st, float pct, float time){
  if (u_bottom) {
    return  1.0 - smoothstep( pct, pct+ u_smooth, sin((st.x - time) / u_sinSize) * u_sinLong + (1.0 - u_positionX));
  } else {
    return  1.0 - smoothstep( pct, pct+ u_smooth, sin((st.y - time) / u_sinSize) * u_sinLong + u_positionX);
  }
}

void main() {
  vec2 st = gl_FragCoord.xy/u_resolution;

  vec2 stY = gl_FragCoord.y/u_resolution;

  float y = u_bottom ? pow(st.y,1.6) : pow(st.x,1.6);


  float mixValue = ${
    cond ? "distance(stY / 3.5,vec2(-0.8,0))" : "distance(stY,vec2(-0.8,0))"
  };
    
  vec3 mixColor = mix(vec3(1.000,1.000,1.000), u_color, mixValue);

  vec3 color = u_bottom ? mixColor : vec3(1.000,1.000,1.000);

  float time = u_time * 0.3;

  float pct = plot(st,y,time);
  color = u_bottom ? (1.0-pct)*color+pct* vec3(1.000,1.000,1.000) : (1.0-pct)*color+pct*mixColor;

  gl_FragColor = vec4(color,1.0);
}`;
