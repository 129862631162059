import * as THREE from "three";

export default class Lights {
  constructor(scene) {
    this._spotLight = new THREE.SpotLight(0xffffff, 4);
    this._spotLight.position.set(0, 0, 100);

    scene.add(this._spotLight);
  }
  initMoodsLights = () => {
    this._spotLight.intensity = 2.25;
    this._spotLight.position.set(0, 10, 50);
  };
}
