import { isMobileOnly } from "mobile-device-detect";

export const Constants = {
  NORMAL_SMOOTH: 0.001,
  NORMAL_LONG: isMobileOnly ? 0.03 : 0.02,
  MODELS_PATH: "/models/",
  MODELS_MOODS: [
    {
      name: "present",
      type: ".glb"
    },
    {
      name: "tree",
      type: ".glb"
    },
    {
      name: "romantic",
      type: ".glb"
    },
    {
      name: "shar",
      type: ".glb"
    },
    {
      name: "party",
      type: ".glb"
    }
  ],
  MODEL_PIZZA: {
    name: "main",
    type: ".glb"
  },
  MOODS_ORIGINALS: [
    {
      position: {
        x: -7.5,
        y: 0,
        z: 0
      },
      rotation: {
        x: 0,
        y: 3.6,
        z: 0
      }
    },
    {
      position: {
        x: -4,
        y: 0.25,
        z: 0
      },
      rotation: {
        x: 0,
        y: 3,
        z: 0
      }
    },
    {
      position: {
        x: 0,
        y: 0,
        z: 0
      },
      rotation: {
        x: 0,
        y: -1.6,
        z: 0
      }
    },
    {
      position: {
        x: 4,
        y: 0.25,
        z: 0
      },
      rotation: {
        x: -0.3,
        y: 2,
        z: 0
      }
    },
    {
      position: {
        x: 7.5,
        y: -0.25,
        z: 0
      },
      rotation: {
        x: 0,
        y: 1.5,
        z: 0
      }
    }
  ],
  COLOR_YELLOW: { r: 1.0, g: 0.223, b: 0.054 },
  COLOR_LIGHT_PINK: { r: 0.188, g: 0.678, b: 0.627 },
  COLOR_BLUE: { r: 0.36, g: 0.756, b: 0.298 },
  COLOR_VIOLET: { r: 0.819, g: 0.117, b: 0.203 },
  COLOR_PINK: { r: 0.415, g: 0.298, b: 0.756 },
  COLOR_ROSE: { r: 0.992, g: 0.509, b: 0.49 },
  COLOR_MENTOL: { r: 0.701, g: 0.666, b: 0.925 }
};
