export const inQuad = n => {
  return n * n;
};

export const outQuad = n => {
  return n * (2 - n);
};

export const outBack = n => {
  let s = 1.70158;
  return --n * n * ((s + 1) * n + s) + 1;
};

export const inElastic = n => {
  let s,
    a = 0.1,
    p = 0.4;
  if (n === 0) return 0;
  if (n === 1) return 1;
  if (!a || a < 1) {
    a = 1;
    s = p / 4;
  } else s = (p * Math.asin(1 / a)) / (2 * Math.PI);
  return -(
    a *
    Math.pow(2, 10 * (n -= 1)) *
    Math.sin(((n - s) * (2 * Math.PI)) / p)
  );
};

export const outElastic = n => {
  let s,
    a = 0.1,
    p = 0.4;
  if (n === 0) return 0;
  if (n === 1) return 1;
  if (!a || a < 1) {
    a = 1;
    s = p / 4;
  } else s = (p * Math.asin(1 / a)) / (2 * Math.PI);
  return a * Math.pow(2, -10 * n) * Math.sin(((n - s) * (2 * Math.PI)) / p) + 1;
};

export const inOutQuad = n => {
  n *= 2;
  if (n < 1) return 0.5 * n * n;
  return -0.5 * (--n * (n - 2) - 1);
};

export const outBounce = n => {
  if (n < 1 / 2.75) {
    return 7.5625 * n * n;
  } else if (n < 2 / 2.75) {
    return 7.5625 * (n -= 1.5 / 2.75) * n + 0.75;
  } else if (n < 2.5 / 2.75) {
    return 7.5625 * (n -= 2.25 / 2.75) * n + 0.9375;
  } else {
    return 7.5625 * (n -= 2.625 / 2.75) * n + 0.984375;
  }
};

export const inBack = n => {
  let s = 1.70158;
  return n * n * ((s + 1) * n - s);
};
