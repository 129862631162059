<template>
  <div id="threeScene"></div>
</template>

<script>
import { onMounted, watch } from "vue";
import * as THREE from "three";
import { useState } from "@/store";
import {
  outQuad,
  outElastic,
  inElastic,
  inQuad,
  outBack,
  inBack,
  inOutQuad
} from "@/js/utils/_easing";

import { Constants } from "../js/modules/THREE/_constants";

import Lights from "../js/modules/THREE/_lights";
import Plane from "../js/modules/THREE/_plane";
import Models from "../js/modules/THREE/_models";

export default {
  setup() {
    /* State */
    const state = useState();

    /* Scene */
    const scene = new THREE.Scene();

    /* Camera */
    const camera = new THREE.PerspectiveCamera(
      50,
      state.system.width / state.system.height,
      0.01,
      1000
    );
    camera.position.z = 17;

    /* Plane */
    const plane = new Plane(
      state.system.width,
      state.system.height,
      state.system.pixelRatio,
      scene
    );

    /* Models */
    const models = new Models(
      state.system.isDesktop,
      state.system.isMobile,
      state.system.centerX,
      state.system.centerY,
      ".motionArea"
    );

    /* Renderer */
    const renderer = new THREE.WebGLRenderer({
      antialias: state.system.pixelRatio <= 1
    });
    renderer.setSize(state.system.width, state.system.height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.outputEncoding = THREE.sRGBEncoding;

    /* Lights */
    const lights = new Lights(scene);

    const render = time => {
      plane.updateAnimation(time);
      models.updateAnimation(time);

      models.updateMoodsCameraMotion({
        ratio: state.system.ratio,
        camera: camera,
        width: state.system.width,
        height: state.system.height,
        onLabelsUpdate: (index, project) => {
          state.THREE.moodsPos[index] = project;
        }
      });

      renderer.render(scene, camera);
      requestAnimationFrame(render);
    };

    /* Updates */

    // Resize
    const updateSize = () => {
      camera.aspect = state.system.width / state.system.height;
      camera.updateProjectionMatrix();

      renderer.setSize(state.system.width, state.system.height);
      plane.updateSize(
        state.system.width,
        state.system.height,
        state.system.pixelRatio
      );
    };

    /* Watchers */
    // Animation watcher
    const animationWatcherInit = () => {
      watch(
        () => state.THREE.scene,
        type => {
          switch (type) {
            case "construct":
              models.init({
                width: state.system.width,
                isMobile: state.system.isMobile,
                scene: scene
              });
              break;
            case "greeting":
              greetingReadyAnimation();
              break;
            case "auth":
              authReadyAnimation();
              break;
            case "authDone":
              authDoneAnimation();
              break;
            case "mood":
              moodReadyAnimation();
              break;
            case "moodSelected":
              moodSelectedAnimation();
              break;
            case "genreSelected":
              genreSelectedAnimation();
              break;
            case "genreSelectCancelled":
              genreSelectCancelledAnimation();
              break;
            case "genreApplied":
              genreAppliedAnimation();
              break;
            case "filmLoadStarts":
              filmLoadStartsAnimation();
              break;
            case "filmLoadEnds":
              filmLoadEndsAnimation();
              break;
            case "reset":
              resetAnimation();
              break;
          }
        }
      );
    };

    // Moods select watcher
    let moodsWatcher;

    const moodsWatcherInit = () => {
      moodsWatcher = watch(
        () => state.THREE.selectedMood,
        (val, oldVal) => {
          if (val !== null) {
            const wrapper = models.moods.children[val],
              model = wrapper.children[0];

            let planeColor, impetusVal;

            switch (val) {
              case 0:
                planeColor = Constants.COLOR_LIGHT_PINK;
                impetusVal = 380;

                models.createAnimation({
                  type: "infinite",
                  until: () => state.THREE.selectedMood !== val,
                  useUid: true,
                  obj: model,
                  duration: 3000,
                  timing: n => outElastic(n),
                  rotY: 3.0,
                  rotX: -0.3
                });
                break;

              case 1:
                planeColor = Constants.COLOR_BLUE;
                state.contentIsWhite = true;
                impetusVal = 200;

                models.createAnimation({
                  useUid: true,
                  obj: model,
                  duration: 1500,
                  timing: n => inOutQuad(n),
                  rotX: -0.2,
                  rotZ: -0.3
                });
                break;

              case 3:
                planeColor = Constants.COLOR_VIOLET;
                impetusVal = -200;

                models.createAnimation({
                  useUid: true,
                  obj: model,
                  duration: 1000,
                  timing: n => outQuad(n),
                  rotY: 3.6,
                  rotX: 0
                });
                break;

              case 4:
                planeColor = Constants.COLOR_PINK;
                impetusVal = -380;

                models.createAnimation({
                  useUid: true,
                  obj: model,
                  duration: 1000,
                  timing: n => outQuad(n),
                  rotY: 1,
                  rotZ: -0.2
                });
                break;

              default:
                planeColor = Constants.COLOR_YELLOW;
                impetusVal = 0;

                models.createAnimation({
                  useUid: true,
                  obj: model,
                  duration: 1500,
                  timing: n => inOutQuad(n),
                  rotX: 0.2,
                  rotZ: 0.4,
                  rotY: -7.2
                });
                break;
            }

            state.contentIsWhite = true;
            models.setImpetusValue(impetusVal);

            plane.createAnimation({
              duration: 500,
              timing: n => outQuad(n),
              type: "color",
              to: planeColor
            });
            plane.createAnimation({
              duration: 300,
              timing: n => outQuad(n),
              type: "long",
              to: state.system.isDesktop ? 0.06 : 0.25
            });
            plane.createAnimation({
              duration: 1000,
              timing: n => outQuad(n),
              type: "long",
              to: 0.01,
              delay: 300
            });

            models.createAnimation({
              z: val === 4 ? -1 : -2,
              obj: wrapper,
              useUid: true,
              duration: 1000,
              timing: n => outBack(n)
            });
          }
          if (val === null || oldVal !== null) {
            const wrapper = models.moods.children[oldVal],
              model = wrapper.children[0];

            models.createAnimation({
              z: 0,
              obj: wrapper,
              useUid: true,
              duration: 500,
              timing: n => outBack(n)
            });

            const rot = Constants.MOODS_ORIGINALS[oldVal].rotation;

            models.createAnimation({
              rotX: rot.x,
              rotY: rot.y,
              rotZ: rot.z,
              useUid: true,
              obj: model,
              duration: 500,
              timing: n => outQuad(n)
            });
          }
        }
      );
    };

    /* Animations
  /----------------------------------
  /----------------------------------
  /----------------------------------
  /----------------------------------
  /----------------------------------
  /----------------------------------
  */

    /* Greeting
  /-----------------------------------
  */
    const greetingReadyAnimation = () => {
      plane.createAnimation({
        duration: 2200,
        timing: n => outElastic(n),
        type: "x",
        to: 0.35
      });
      plane.createAnimation({
        duration: 1100,
        timing: n => outQuad(n),
        type: "long",
        to: Constants.NORMAL_LONG
      });

      models.pizza.position.z = -100;

      models.createAnimation({
        obj: models.pizza,
        duration: 500,
        z: state.system.width < 1024 ? -5 : -2,
        timing: n => outQuad(n)
      });

      models.createAnimation({
        obj: models.pizza,
        duration: 3500,
        delay: 500,
        useUid: true,
        rotY: 0,
        timing: n => outElastic(n)
      });
    };

    /* Auth
  /-----------------------------------
  */
    const authReadyAnimation = () => {
      plane.createAnimation({
        duration: 2000,
        timing: n => outElastic(n),
        type: "x",
        to: 0.15
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12
      });
      plane.createAnimation({
        duration: 1000,
        timing: n => outQuad(n),
        type: "long",
        to: Constants.NORMAL_LONG,
        delay: 300
      });

      models.createAnimation({
        obj: models.pizza,
        duration: 1000,
        z: state.system.width < 1024 ? -3 : 0.5,
        timing: n => outElastic(n)
      });
    };

    /* Auth Done
  /-----------------------------------
  */
    const authDoneAnimation = () => {
      plane.createAnimation({
        duration: 2000,
        timing: n => inElastic(n),
        type: "x",
        to: 1.5
      });
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "long",
        to: 0.5,
        delay: 1500
      });

      models.createAnimation({
        obj: models.pizza,
        duration: 1000,
        delay: 1500,
        y: -20,
        z: -10,
        timing: n => inQuad(n)
      });
      models.createAnimation({
        obj: models.pizza,
        duration: 2000,
        rotY: 3.2,
        useUid: true,
        timing: n => inElastic(n)
      });
    };

    /* Moods ready
  /-----------------------------------
  */
    const moodReadyAnimation = () => {
      lights.initMoodsLights();
      plane.changeDiraction("bottom");

      plane.createAnimation({
        duration: state.system.isAdaptive ? 2500 : 2000,
        timing: n => outElastic(n),
        type: "x",
        to: state.system.isAdaptive ? -0.1 : 0.6
      });
      plane.createAnimation({
        duration: state.system.isAdaptive ? 1250 : 1000,
        timing: n => outQuad(n),
        type: "long",
        to: 0.01
      });

      camera.position.x = 0;
      setTimeout(() => {
        models.enableMoodsSelect(state.system.centerX, () => {
          if (state.system.isAdaptive && state.THREE.scene === "mood") {
            state.THREE.selectedMood = null;
          }
        });
        state.THREE.selectIsEnable = true;
        moodsWatcherInit();
      }, 1000);

      models.moods.children.forEach((mood, index) => {
        const delay =
          index === 0
            ? 1300
            : index === 1
            ? 700
            : index === 2
            ? 300
            : index === 3
            ? 900
            : 1100;

        models.createAnimation({
          y: 0.5,
          obj: mood,
          duration: 500,
          timing: n => outBack(n),
          delay: delay
        });
        models.createAnimation({
          rotX: 0,
          obj: mood,
          duration: 500,
          timing: n => outBack(n),
          delay: delay + 200
        });

        models.createAnimation({
          type: "infinite",
          until: () =>
            state.THREE.scene != "mood" && state.THREE.scene != "moodSelected",
          obj: mood.children[0],
          duration: 2500,
          timing: n => inOutQuad(n),
          y: index === 3 || index === 1 || index === 4 ? 0 : 0.25
        });
      });
    };

    /* Mood selected
  /-----------------------------------
  */
    const moodSelectedAnimation = () => {
      models.disableMoodsSelect();
      moodsWatcher();
      state.THREE.selectIsEnable = false;

      const current = state.THREE.selectedMood;
      state.contentIsWhite = false;

      models.moods.children.forEach((mood, index) => {
        if (index !== current) {
          models.createAnimation({
            y: -20,
            rotX: 4,
            obj: mood,
            useUid: true,
            duration: 500,
            timing: n => inBack(n),
            delay: 200 * index,
            then: () => {
              mood.rotation.x = -1;
            }
          });
        }
      });

      const currentWrapper = models.moods.children[current];
      const currentModel = models.moods.children[current].children[0];
      const rot = Constants.MOODS_ORIGINALS[current].rotation;

      models.createAnimation({
        rotX: rot.x,
        rotY: rot.y,
        rotZ: rot.z,
        useUid: true,
        obj: currentModel,
        duration: 1000,
        timing: n => outQuad(n)
      });

      models.createAnimation({
        z: 4,
        y: 3,
        x:
          current === 0 && state.system.isDesktop
            ? 3
            : current === 4 && state.system.isDesktop
            ? -3
            : null,
        useUid: true,
        obj: currentWrapper,
        duration: 500,
        timing: n => outBack(n)
      });

      models.createAnimation({
        y: -20,
        rotX: 4,
        z: 1,
        useUid: true,
        obj: currentWrapper,
        duration: 700,
        timing: n => inBack(n),
        delay: 1200,
        then: () => {
          currentWrapper.rotation.x = -1;
          currentWrapper.position.x = 0;
        }
      });

      plane.createAnimation({
        duration: 2000,
        timing: n => outElastic(n),
        type: "x",
        to: 0.1
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12
      });
      plane.createAnimation({
        duration: 1000,
        timing: n => outQuad(n),
        type: "long",
        to: Constants.NORMAL_LONG,
        delay: 300
      });

      plane.createAnimation({
        duration: 2000,
        timing: n => outElastic(n),
        type: "x",
        to: 0.7,
        delay: 1400
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12,
        delay: 1400
      });
      plane.createAnimation({
        duration: 1000,
        timing: n => outQuad(n),
        type: "long",
        to: 0.01,
        delay: 1700
      });

      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "color",
        to: Constants.COLOR_ROSE,
        delay: 1400
      });
    };

    /* Genre selected
  /-----------------------------------
  */
    const genreSelectedAnimation = () => {
      plane.createAnimation({
        duration: 2000,
        timing: n => outElastic(n),
        type: "x",
        to: -0.1
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12
      });
      plane.createAnimation({
        duration: 1000,
        timing: n => outQuad(n),
        type: "long",
        to: 0.01,
        delay: 300
      });
    };

    /* Genre selected cancelled
  /-----------------------------------
  */
    const genreSelectCancelledAnimation = () => {
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "x",
        to: 0.7
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12
      });
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "long",
        to: 0.01,
        delay: 300
      });
    };

    /* Genre applied
  /-----------------------------------
  */
    const genreAppliedAnimation = () => {
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "x",
        to: 0.2
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12
      });
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "long",
        to: Constants.NORMAL_LONG,
        delay: 300
      });

      plane.createAnimation({
        duration: 2000,
        timing: n => inElastic(n),
        type: "x",
        delay: 500,
        to: 1.5
      });
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "long",
        to: 0.5,
        delay: 2000
      });
    };

    /* Film load starts
  /-----------------------------------
  */
    const filmLoadStartsAnimation = () => {
      plane.changeDiraction("right");

      plane.createAnimation({
        duration: 1,
        timing: n => n,
        type: "color",
        to: Constants.COLOR_YELLOW
      });

      plane.createAnimation({
        duration: 2500,
        timing: n => outElastic(n),
        type: "x",
        to: -0.2
      });
      plane.createAnimation({
        duration: 1300,
        timing: n => outQuad(n),
        type: "long",
        to: Constants.NORMAL_LONG
      });
    };

    /* Film load ends
  /-----------------------------------
  */
    const filmLoadEndsAnimation = () => {
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "color",
        to: state.response.color ? state.response.color : Constants.COLOR_MENTOL
      });

      plane.createAnimation({
        duration: 2000,
        timing: n => outElastic(n),
        type: "x",
        to: 0.2,
        delay: 500
      });
      plane.createAnimation({
        duration: 300,
        timing: n => outQuad(n),
        type: "long",
        to: 0.12,
        delay: 500
      });
      plane.createAnimation({
        duration: 1000,
        timing: n => outQuad(n),
        type: "long",
        to: Constants.NORMAL_LONG,
        delay: 800
      });
    };

    /* Reset
  /-----------------------------------
  */
    const resetAnimation = () => {
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "color",
        to: Constants.COLOR_YELLOW
      });

      plane.createAnimation({
        duration: 2000,
        timing: n => inElastic(n),
        type: "x",
        to: 1.5
      });
      plane.createAnimation({
        duration: 500,
        timing: n => outQuad(n),
        type: "long",
        to: 0.5,
        delay: 1500
      });

      models.reset();
    };

    /* Inition */
    const init = () => {
      document.getElementById("threeScene").appendChild(renderer.domElement);

      models.load({
        quantity: !state.user ? "all" : "onlyMoods",
        beforeLoad: () => state.THREE.loadingProgress.push(0),
        loadDone: index => {
          state.THREE.loadingProgress[index] = 1;
        }
      });
      render();
      window.addEventListener("resize", updateSize, { passive: true });
      animationWatcherInit();
    };

    onMounted(() => {
      state.THREE.isReady = true;
      init();
    });
  }
};
</script>
